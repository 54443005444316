import React, { ElementType, ReactNode } from "react";
import _ from "lodash";
import { cn } from "@/components/cn";

interface TitleProps {
  as?: ElementType;
  children: ReactNode;
  variant?: "extra-large" | "large" | "medium" | "small";
  className?: string;
  style?: React.CSSProperties;
}

const defaultStyles = {
  fontSize: "text-[28px]",
  fontWeight: "font-bold",
  lineHeight: "leading-9",
};

function getStyles(variant: string) {
  const styles = _.cloneDeep(defaultStyles);
  switch (variant) {
    case "extra-large":
      styles.fontSize = "text-[28px] sm:text-[40px]";
      styles.lineHeight = "leading-[36px] sm:leading-[52px]";
      break;
    case "large":
      styles.fontSize = cn(defaultStyles.fontSize, "sm:text-[32px]");
      styles.lineHeight = "leading-10";
      break;
    case "medium":
      styles.fontSize = "text-[28px]";
      break;
    case "small":
      styles.fontSize = "text-2xl";
      styles.fontWeight = "font-bold";
      styles.lineHeight = "leading-8";
      break;
    default:
      break;
  }

  return _.values(styles);
}

const Title = ({ as: Tag = "h1", children, variant = "medium", className = "", style }: TitleProps) => {
  return (
    <Tag className={cn(className, getStyles(variant))} style={style}>
      {children}
    </Tag>
  );
};

export default Title;
