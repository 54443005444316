import React from "react";
import { useTranslation } from "next-i18next";
import { Spacer } from "@nextui-org/react";
import Image from "next/image";

import Title from "@/components/typography/title";
import Body from "@/components/typography/body";
import { cn } from "@/components/cn";

import Subscribe from "./subscribe";

export default function Component({ className }: { className?: string }) {
  const { t } = useTranslation("common");

  return (
    <section className={cn("container", className)}>
      <div>
        {/* Background texture */}
        <div className="hidden sm:block absolute w-[1248px] h-[440px]">
          <div className="absolute w-[108px] h-[108px] top-[30px] opacity-40 bg-[#262627]" />
          <div className="absolute w-[108px] h-[108px] bottom-[83px] opacity-40 bg-[#262627]" />
          <div className="absolute w-[108px] h-[108px] left-[544px] top-[139px] opacity-40 bg-[#262627]" />
          <div className="absolute w-[108px] h-[108px] right-[0px] top-[30px] opacity-40 bg-[#262627]" />
          <div className="absolute w-[108px] h-[108px] right-[0px] bottom-[83px] opacity-40 bg-[#262627]" />
          <Image
            className="absolute top-[50px]"
            src="/image/home/cta/lines.svg"
            width={1088}
            height={392}
            alt="lines"
          />
          <Image
            className="absolute top-[-108px]"
            src="/image/home/cta/horizontal-lines.svg"
            width={1088}
            height={392}
            alt="horizontal-lines"
          />
        </div>
        <div
          className="py-12 px-12 rounded-lg relative"
          style={{
            background: "var(--Glass-Grey-1, rgba(161, 161, 170, 0.16))",
          }}
        >
          <div>
            <Spacer y={4} />
            <Title as="p" variant="large">
              {t("cta.look-for-more")}
            </Title>
            <Title as="h2" variant="large">
              {t("cta.subscribe-to-our-newsletter")}
            </Title>
            <Spacer y={4} />
            <Body as="p" className="max-w-[609px]">
              {t("cta.subscribe-description")}
            </Body>
          </div>
          <Spacer y={16} />
          <div className="w-full sm:w-1/2">
            <Subscribe />
          </div>
        </div>
      </div>
    </section>
  );
}
