import React from "react";
import { Input, Button } from "@nextui-org/react";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { useTranslation } from "next-i18next";

import { post } from "@/utils/api";

import { getSubscribeFormError } from "../utils/errors/uiErrors";

export default function Component() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { t } = useTranslation("common");

  const onSubmit = async (data) => {
    toast.info(t("cta.submit-pending")); // Display a success message

    try {
      const result = await post("/email-subscribe", data); // Send the email to the server
      if (result.error) {
        toast.error(t("cta.submit-error")); // Display an error message
        return;
      }
    } catch (error) {
      toast.error(t("cta.submit-error")); // Display an error message
      return;
    }

    toast.success(t("cta.submit-success")); // Display a success message
  };

  return (
    <form className="flex flex-wrap gap-4" onSubmit={handleSubmit(onSubmit)}>
      <Input
        type="email"
        radius="sm"
        className="w-full sm:w-auto sm:grow"
        classNames={{
          input: ["autofill-input-text-black"],
          inputWrapper: ["bg-white", "focus-within:!bg-white", "dark:hover:bg-white"],
        }}
        style={{ borderRadius: 12, boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)", color: "black" }}
        placeholder={t("cta.enter-your-email")}
        {...register("email", {
          required: true,
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Email address is invalid",
          },
        })}
        errorMessage={getSubscribeFormError(errors?.email)}
      />
      <div className="w-full sm:w-24">
        <Button color="primary" radius="sm" size="md" type="submit" value="submit" fullWidth={true}>
          {t("cta.subscribe")}
        </Button>
      </div>
    </form>
  );
}
