import _ from "lodash";
import { FieldErrors } from "react-hook-form";
/**
 * Define error messages for the email input field
 * @param fieldError
 * @returns {string} error message for display
 */
export const getSubscribeFormError = (fieldError: any): string => {
  switch (fieldError?.type) {
    case "required":
      return "Email address is required";
    case "pattern":
      return "Email address is invalid";
    default:
      return "";
  }
};

/**
 * Define error messages for the form input fields
 * @param {FieldErrors<any>} errors - The errors object from react-hook-form
 * @param {string} fieldKey - The key of the field for which the error message is to be retrieved
 * @returns {string} The error message for display
 */
export const getContactFormError = (errors: FieldErrors<any>, fieldKey: string): string => {
  const error = errors[fieldKey];
  if (!error) {
    return ""; // No error for the specified field
  }

  switch (error.type) {
    case "required":
      return "This field is required.";
    case "pattern":
      return "The input format is invalid.";
    case "minLength":
      return `This field requires at least ${error.message} characters.`;
    case "maxLength":
      return `This field can have at most ${error.message} characters.`;
    case "validate":
      return "This field is invalid."; // Custom validation message
    default:
      return "This field is invalid.";
  }
};
