import _ from 'lodash';

/**
 * Process the response from the server
 * If the response is not ok, throw an error
 * If the response is empty, return null
 * @param resp 
 * @returns JSON response or null
 */
const processResponse = async (resp: Response) => {
	if (!resp.ok) {
		throw new Error("Failed to request the server.");
	}

	let result = await resp.text();
	if (_.isEmpty(result)) {
		return null;
	}

	return JSON.parse(result);
}

const getHeaders = (extraHeaders = {}) => {
	return {
		...extraHeaders,
		"Content-Type": "application/json",
  	};
}

const getEndpoint = (path: string, params?: { [key: string]: any } | null) => {
	let endpoint = `/api/${encodeURI(path)}`;

	if (params) {
		endpoint += "?" + new URLSearchParams(params);
	}

	return endpoint;
}

export const post = async (path: string, body: any) => {
	const resp = await fetch(getEndpoint(path), {
		headers: getHeaders(),
		method: "POST",
		body: body ? JSON.stringify(body) : undefined,
	});

	return processResponse(resp);
}

export const get = async (path: string, params?: { [key: string]: any } | null) => {
	const resp = await fetch(getEndpoint(path, params), {
		headers: getHeaders(),
		method: "GET",
	});

	return processResponse(resp);
}

/**
 * Call a Parse Cloud Function
 * @param cloudFunc Function name
 * @param params Function parameters
 * @returns Cloud function result
 */
export const callParseCloudFunc = async (cloudFunc: string, params: any) => post('api', { cloudFunc, params });
