export const breakpoints = {
  sm: 640, // Small screen start
  md: 768, // Medium screen start
  lg: 1024, // Large screen start
  xl: 1280, // Extra Large screen start
  "2xl": "(min-width: 1536px)", // Extra Extra Large screen start
};

export const device = {
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  "2xl": `(min-width: ${breakpoints["2xl"]}px)`,
};
