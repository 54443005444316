import React from "react";
import _ from "lodash";
import { cn } from "@/components/cn";

interface SubtitleProps {
  as: "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  variant?: "large" | "medium" | "medium-strong";
  children: React.ReactNode;
  className?: string;
}

const defaultStyles = {
  fontSize: "text-base",
  fontWeight: "font-medium",
  lineHeight: "leading-normal",
};

function getStyles(variant: SubtitleProps["variant"]) {
  let styles = _.cloneDeep(defaultStyles);
  switch (variant) {
    case "large":
      styles.fontSize = "text-xl";
      break;
    case "medium":
      styles.fontSize = "text-base";
      break;
    case "medium-strong":
      styles.fontSize = "text-base";
      styles.fontWeight = "font-bold";
      break;
    default:
      break;
  }

  return _.values(styles);
}

const Subtitle: React.FC<SubtitleProps> = ({ as: Tag, variant = "medium", children, className = "" }) => {
  return <Tag className={cn(getStyles(variant), className)}>{children}</Tag>;
};

export default Subtitle;
